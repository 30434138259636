import { useEffect, useState } from "react";
import { Dots } from "react-activity";
import "react-activity/dist/Dots.css";
import { useNavigate } from "react-router-dom";
import Carousel from "../../components/global/Carousel";
import { Header } from "../../components/global/Header";
import { Bill } from "../../components/pages/Checkin/Bill";
import { Credit } from "../../components/pages/Checkin/Credit";
import { useCart } from "../../context/cart";
import { authGetRequisition, loginVerifyAPI } from "../../utils/api";
import { Container } from "./styles";

export function Checkin() {
  const { duration, id, credits, setCart } = useCart();
  const [plan, setPlan] = useState<any>();
  const [selected, setSelected] = useState("");
  const [loading, setLoading] = useState(true);
  const navigation = useNavigate();
  useEffect(() => {
    async function isLogged() {
      const verify = await loginVerifyAPI();
      if (verify !== 200) {
        localStorage.setItem("truepayscreen", "/checkin");
        navigation("/login");
      }
    }

    async function getDetails() {
      const cart = localStorage.getItem("truepaycart");
      if (cart) {
        const details = await authGetRequisition(`/cart/${cart}?type=cart`);
        if (details.status === 200) {
          setPlan(details.body);
          setLoading(false);
          setCart(details.body._id);
        }
      } else {
        if (duration && id) {
          const details = await authGetRequisition(
            `/cart/${id}?qnt=${duration}&credit=${credits}`
          );
          if (details.status === 200) {
            setPlan(details.body);
            setLoading(false);
            setCart(details.body._id);
          }
        } else {
          const duration = localStorage.getItem("truepayduration");
          const id = localStorage.getItem("truepayid");
          const credit = localStorage.getItem("truepaycredits");
          const details = await authGetRequisition(
            `/cart/${id}?qnt=${duration}&credit=${credit}`
          );
          if (details.status === 200) {
            setPlan(details.body);
            setLoading(false);
            setCart(details.body._id);
          }
        }
      }
    }

    getDetails();
    isLogged();
    return;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Header />
      <Container>
        <div className="payment">
          <div className="column">
            <div className="button">
              <Carousel>
                <button
                  onClick={() => setSelected("cash")}
                  className="selector"
                >
                  <img src={require("../../assets/pix.png")} alt="" />
                  <h1>Pix / Boleto</h1>
                  <div
                    className="indicator"
                    style={
                      selected === "cash" ? selectedStyle : notSelectedStyle
                    }
                  ></div>
                </button>
                <button
                  onClick={() => setSelected("credit")}
                  className="selector"
                >
                  <img src={require("../../assets/credit.png")} alt="" />
                  <h1>Cartão de Crédito</h1>
                  <div
                    className="indicator"
                    style={
                      selected === "credit" ? selectedStyle : notSelectedStyle
                    }
                  ></div>
                </button>
              </Carousel>
            </div>
            <div
              style={{
                width: window.innerWidth < 1120 ? "100vw" : "50vw",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {plan ? (
                <>
                  <Bill
                    selectedType={selected}
                    value={Number(plan.value)}
                    quantity={plan.duration}
                    id={plan._id}
                  />
                  <Credit selectedType={selected} details={plan} />
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
        <div className="cart">
          <div className="card">
            {loading ? (
              <Dots size={50} />
            ) : (
              <>
                <img src={plan.icon} alt="" />
                <div className="info">
                  <div className="subInfo">
                    <strong className="title">
                      {plan.duration}x - plano {plan.name}
                    </strong>
                    <strong className="subtitle">
                      Com {plan.credits} créditos
                    </strong>
                  </div>
                  <div className="subInfo">
                    <strong className="money">
                      {Number(plan.value).toLocaleString("pt-br", {
                        style: "currency",
                        currency: "BRL",
                      })}
                    </strong>
                  </div>
                </div>
                {plan.license && plan.license.duration ? (
                  <div className="info">
                    <div className="subInfo">
                      <strong className="title">
                        Licença {plan.productName}
                      </strong>
                      <strong className="subtitle">
                        Com duração de{" "}
                        {plan.license.duration === 1
                          ? `${plan.license.duration} mês`
                          : `${plan.license.duration} meses`}
                      </strong>
                    </div>
                    <div className="subInfo">
                      <strong className="money">
                        {Number(plan.license.value).toLocaleString("pt-br", {
                          style: "currency",
                          currency: "BRL",
                        })}
                      </strong>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </>
            )}
          </div>
          <button onClick={() => navigation("/")}>Voltar para o início</button>
          <button onClick={() => navigation(-1)}>Alterar plano</button>
        </div>
      </Container>
    </>
  );
}

const selectedStyle = {
  backgroundColor: "#00B800",
};

const notSelectedStyle = {
  backgroundColor: "gray",
};
