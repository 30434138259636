import { Button, ButtonLink, Container, Description, Footer, Header, Title } from "./styles";

export function PlanCard({ item, onClick }: any) {
    return (
        <Container >
            <Header>
                <Title>
                    {item.name}
                </Title>
            </Header>
            <Description>
                {item.description}
            </Description>
            <Footer>
                Créditos expiram ao final do período contratado
            </Footer>
            {
                item.type === 'link' ? 
                <ButtonLink target="_blank" href={item.whatsappLink}>Ver detalhes</ButtonLink>
                : 
                <Button onClick={onClick} >
                Ver detalhes
            </Button>
            }
        </Container>
    )
}