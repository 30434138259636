import { useState } from "react";
import { Spinner } from "react-activity";
import "react-activity/dist/Spinner.css";
import { useNavigate } from "react-router-dom";
import { UserTermsModal } from "../../components/pages/Register/UseTermsModal";
import { postRequisition } from "../../utils/api";
import {
  maskCpfCnpj,
  maskPhone,
  passwordValidation,
  removeSpace,
} from "../../utils/textInputFormat";
import { userTerms } from "../../utils/userTerms";
import { Container } from "./styles";
export function Register() {
  const [useTerm, setUseTerm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [mobilePhone, setMobilePhone] = useState("");
  const [cpfCnpj, setCpfCnpj] = useState("");
  const [answer, setAnswer] = useState("");
  const navigation = useNavigate();

  function IsEmail(email: string) {
    const regexEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    console.log("entrou");
    console.log(regexEmail.test(email));
    return regexEmail.test(email);
  }

  async function handleRegister() {
    const data = {
      name,
      email,
      password,
      repeatPassword,
      mobilePhone,
      cpfCnpj,
      userTerms,
    };

    if (!useTerm) {
      return setAnswer("Aceite os termos de uso para continuar");
    }
    if (!IsEmail(data.email)) {
      return setAnswer("Insira um email válido");
    }
    const passValidation = passwordValidation(password);
    if (!passValidation) {
      return setAnswer(
        "Sua senha deve conter 8 dígitos, 1 letra maiúscula, 1 minúscula, 1 número e 1 símbolo (@$#!*)"
      );
    }
    setLoading(true);
    const register = await postRequisition("/register", data);
    setLoading(false);
    if (register.status !== 200) {
      return setAnswer(register.body);
    }
    return navigation("/login");
  }

  return (
    <Container>
      <img src={require("../../assets/logo.png")} alt="" />

      <div className="form">
        <label htmlFor="name">Nome</label>
        <input
          type="name"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </div>
      <div className="form">
        <label htmlFor="email">email</label>
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(removeSpace(e.target.value))}
        />
      </div>
      <div className="form">
        <label htmlFor="password">Senha</label>
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </div>
      <div className="form">
        <label htmlFor="password">Repita a Senha</label>
        <input
          type="password"
          value={repeatPassword}
          onChange={(e) => setRepeatPassword(e.target.value)}
        />
      </div>
      <div className="form">
        <label htmlFor="mobilePhone">Telefone</label>
        <input
          maxLength={14}
          type="mobilePhone"
          value={mobilePhone}
          onChange={(e) => setMobilePhone(maskPhone(e.target.value))}
        />
      </div>
      <div className="form">
        <label htmlFor="cpfCnpj">CPF ou CNPJ</label>
        <input
          maxLength={18}
          type="cpfCnpj"
          value={cpfCnpj}
          onChange={(e) => setCpfCnpj(maskCpfCnpj(e.target.value))}
        />
      </div>
      <UserTermsModal check={useTerm} change={() => setUseTerm(!useTerm)} />
      <div className="button">
        <h5>{answer}</h5>

        <button
          disabled={loading}
          className="register"
          onClick={handleRegister}
        >
          {loading ? <Spinner /> : "Registrar"}
        </button>

        <button onClick={() => navigation("/login")}>Entrar</button>
      </div>
    </Container>
  );
}
