import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { postRequisition } from "../../utils/api";
import { removeSpace } from "../../utils/textInputFormat";
import { Container } from "./styles";
import { Spinner } from "react-activity";
import "react-activity/dist/Spinner.css";

export function Login() {

    const [loading, setLoading] = useState(false)
    const [answer, setAnswer] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const navigation = useNavigate()

    async function handleLogin() {
        setLoading(true)
        const login = await postRequisition('/login', { email, password })
        setLoading(false)
        if (login.status !== 200) {
            return setAnswer(login.body)
        }
        localStorage.setItem('truepaytoken', login.body)
        const screen = localStorage.getItem('truepayscreen')

        if (screen)
            return navigation(screen)
        else
            return navigation('/checkin')
    }

    return (
        <Container>
            <img src={require('../../assets/logo.png')} alt="" />
            <div className="form">
                <label htmlFor="email">email</label>
                <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(removeSpace(e.target.value))}
                />
            </div><div className="form">
                <label htmlFor="password">Senha</label>
                <input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
            </div>
            <div className="button" >
                <h5>{answer}</h5>
                <button disabled={loading} className="login" onClick={handleLogin} >
                    {
                        loading ?
                            <Spinner /> :
                            'Login'
                    }
                </button>
                <button onClick={() => navigation('/register')}>Cadastro</button>
                <button className="out" onClick={() => navigation('/')}>Voltar</button>
            </div>
        </Container>
    )
}