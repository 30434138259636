import styled from 'styled-components'


export const Container = styled.div`
display: flex;
flex-direction:row;
align-items: center;
justify-content: center;
margin-top: 10px;
h1{
  font-size: 1.3rem;
  margin: 1rem;
  color: white;
}
div.form{
  margin-top:1rem ;
  width: 30rem;
  height: 100rem;
  padding: 1rem;
}
`
export const Wrapper = styled.div`
  padding: 0.5rem;
  color: white;
  width: 35rem;
  max-width: 96vw;
  background-color: var(--primary);
  height: auto;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  button{
    padding:0.5rem 1.3rem;
    width: 10rem;
    display: flex ;
    border-width: 0;

    align-items: center;
    justify-content: center;
    border-radius: 0.5rem;
    border-width: 0.1rem;
    margin-top: 0.5rem;
    font-size: 1.3rem;
    background-color: var(--green);
    transition: filter 0.2s;

    &:hover{
    filter: brightness(0.9);
}
  }

  strong{
    margin-top:0.5rem;
    font-weight: bold;
    font-size: 1.3rem;
    color: var(--red);
  }
`;

export const BottomBox = styled.div`
  width: 98%;
  display: flex;
  justify-content: space-between;
  .expiry {
    width: 40%;
    input{
      width: 100%;
    }
  }
  .cvc {
    width: 40%;
    input{
      width: 100%;
    }
  }
`;

export const Inputs = styled.div`
  width:100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  input {
    width: 98%;
    height: 3rem;
    border-radius: 0.5rem;
    border-width: 0.1rem;
    margin-top: 0.5rem;
    padding: 1rem;
    text-align: center;
    font-size: 1.2rem;
    font-weight: bold;
    border-color: var(--light);
    background-color: var(--primary);
    color: var(--light);
  }
`;

export const Error = styled.span`
  font-size: 1rem;
  font-weight: bold;
  color: red;
`;