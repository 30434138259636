import moment from "moment";
import { Dots } from "react-activity";
import "react-activity/dist/Dots.css";
import { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useNavigate } from "react-router-dom";
import Carousel from "../../components/global/Carousel";
import { Header } from "../../components/global/Header";
import { authDeleteRequisition, authGetRequisition, loginVerifyAPI } from "../../utils/api";
import { Container, SecondaryContainer } from "./styles";


export function Profile() {
    const navigation = useNavigate()
    const alert = useAlert()
    const [plans, setPlans] = useState<any[]>([])
    const [info, setInfo] = useState<any>()
    const [loading, setLoading] = useState(false)


    async function profileInfo() {
        const info = await authGetRequisition('/profile')
        setLoading(false)
        setInfo(info?.body)
        setPlans(info?.body.plans)
    }

    async function handleDelete(id: string) {
        
        setLoading(true)
        const exclude = await authDeleteRequisition(`/plan/${id}`)

        if (exclude.status === 200) {
            return profileInfo()
        }
        setLoading(false)
        return alert.show(exclude.body)

    }

    function handlePayment(link:string) {
       
    }

    function handleLogout() {
        localStorage.removeItem('truepaytoken')
        navigation('/')
    }


    useEffect(() => {
        
        async function isLogged() {

            setLoading(true)
            const verify = await loginVerifyAPI()
            if (verify !== 200) {
                localStorage.setItem('screen', '/profile')
                navigation('/login')
            } else {
                profileInfo()
            }
        }

        isLogged()
        // eslint-disable-next-line react-hooks/exhaustive-deps

    }, [navigation])

    return (
        <>
            <Header />
                    <Container>
                    {   
                        loading ?
                        <Dots size={50} /> :
                        
                        !info ? <></> :
                        <>
                            <div className="profile" >
                                <strong>
                                    Bem vindo!
                                </strong>
                                <strong className="name">
                                    {info.name}
                                </strong>
                                <strong>
                                    {info.phone}
                                </strong>
                                <button onClick={handleLogout} >
                                    Sair
                                </button>
                            </div>
                            <SecondaryContainer>
                        {
                            plans.length === 0 ? 
                            <h1>Nenhum plano encontrado</h1> : 
                            <Carousel>
                            {
                                plans.length === 0 ?
                                    <></> :
                                    plans.map((info:any, key:any) => (
                                        <div key={key.toString()} className="card">
                                            <strong>
                                                {info.product}
                                            </strong>
                                            <label>
                                                Duração da contratação
                                            </label>
                                            <strong>
                                                {info.duration} {info.duration === 1 ? 'mês' : 'meses'}
                                            </strong>
                                            <label>
                                                Plano Contratado
                                            </label>
                                            <strong>
                                                {info.plan}
                                            </strong>
                                            <label>
                                                Data da expiração
                                            </label>
                                            <strong>
                                                {moment(info.buyAt).add(info.duration, 'months').format('DD/MM/yy')}
                                            </strong>
                                            <label>
                                                Status
                                            </label>
                                            <strong className={info.status === 'A' ? 'active' : 'inactive'}>
                                                {
                                                    info.status === 'I' ? 'Aguardando Pagamento' : info.status === 'F' ? 'Contrato finalizado' :
                                                        'Contrato ativo'
                                                }
                                            </strong>
                                            <button onClick={ ()=> window ? window.open(info.paymentLink): handlePayment(info.paymentLink)} >
                                                Lista de pagamentos
                                            </button>
                                            {
                                                info.status !== 'A' ?
                                                    <button onClick={() => handleDelete(info._id)} className="exclude">
                                                        Excluir
                                                    </button>
                                                    : <></>
                                            }
                                        </div>
                                    ))

                            }
                        </Carousel>
                        }
                    </SecondaryContainer>
                    <button onClick={() => navigation('/')} className="back">
                        Inicio
                    </button>
                            </>
                    }

                    
                </Container>
                            
        </>
    )
}