import { useEffect, useState } from "react";
import { CreditCardForm } from "../../../global/CreditCardForm";

interface Props {
  details: any
  selectedType:string
}

export function Credit({ details, selectedType }: Props) {

  const [installment, setInstallment] = useState<any>([])

  useEffect(() => {

    const list = []

    for (let i = 1; i <= details.duration; i++) {
      const data = {
        value: i,
        label: `${i}x de R$ ${(details.value / i).toFixed(2)}`
      }

      list.push(data)
    }
    setInstallment(list)
        // eslint-disable-next-line react-hooks/exhaustive-deps

  }, [details])


  return (
     <>
     {
      selectedType !== 'credit' ? 
      <></> : 
      <CreditCardForm installment={installment} details={details} />
     }
     </>
  )
}