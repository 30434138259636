import styled from "styled-components";

export const Container = styled.div`
display: flex;
padding: 0px;
overflow: hidden;
border-radius: 10px;
align-items: center;
min-width: 200px;
min-height:180px;
margin:0.5rem;
flex-direction:column;
justify-content: space-between;
background-color: var(--primary);
`

export const Header = styled.div`
margin: 0;
display: flex;
align-items: center;
justify-content: center;
padding: 0.2rem;
background-color:var(--dark);
width: 100%;
`

export const Title = styled.strong`
color: var(--light);
font-size: 1.1rem;
`

export const Description = styled.strong`
 margin-top: 0.5rem;
color: var(--light);
text-align: center;
padding: 0.2rem;
font-size:0.875rem;
`

export const Footer = styled.strong`
 margin-top: 0.5rem;
font-size: 1.2rem;
color: var(--light);
text-align: center;
padding: 0.4rem;
font-size:1.2rem;
font-size: 0.6rem;
padding: 0.1rem;
color: var(--gray);
`

export const Button = styled.button`
padding: 0.3rem 0.8rem;
border-radius: 0.5rem;
background-color: var(--green);
margin: 1rem;
font-size: 0.875rem;
transition: filter 0.2s;
font-weight: 600;
border-width: 0.5px;
border-color: var(--light);
color: var(--light);

    &:hover{
        filter: brightness(0.9);
    }
`
export const ButtonLink = styled.a`
padding: 0.3rem 0.8rem;
border-radius: 0.5rem;
background-color: var(--green);
margin: 1rem;
font-size: 0.875rem;
transition: filter 0.2s;
font-weight: 600;
border: solid;
border-width: 0.5px;
border-color: var(--light);
text-decoration:none;
color: var(--light);

    &:hover{
        filter: brightness(0.9);
    }

    &:hover{
        filter: brightness(0.9);
    }
`