import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    width: 100%;
    height: 100vh;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    img{
        width: 10rem;
        height: 10rem;
    }

    div{
        input{
            border-radius: 1rem;
            border-width: 0px;
            height: 3rem;
            width: 20rem;
            padding-left: 0.5rem;
            background-color: var(--primary);
            color: var(--light);
        }
        label{
            font-size: 0.875rem;
            color: var(--light);

        }

        &.form{
            display: flex;
            flex-direction: column;
            margin-top: 0.5rem;
        }
        &.button{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            h5{
                font-size: 1rem;
                color: var(--red);
            }
        button{
            margin-top: 1rem;
            border-radius: 1rem;
            width:20rem;
            height: 3rem;
            display: flex;
            align-items: center;
            justify-content: center;
            border-width: 0px;
            font-size: 1.3rem;
            font-weight: 600;
            color: var(--light);
            background-color: var(--secondary) ;
            transition: filter 0.2s;

                    &:hover{
                        filter: brightness(0.7);
                    }
        
        &.login{
            background-color: var(--green);
        }
        &.out{
            background-color: var(--primary);
            width:16rem;

        }
                }
        }
    }

`