import styled from "styled-components";

export const Container = styled.div`
display: flex;
width: 100%;
height: 100vh;
align-items: center;
flex-direction:column;

h1{
    color: white;
    font-size: 1.5rem;
    margin: 3rem;
}
    div.profile{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin-top: 1rem;
        width: 20rem;
        height: 10rem;
        border-radius: 0.5rem;
        background-color: var(--secondary);

        button{
            background-color: transparent;
            border-width: 0;
            color: var(--red);
            text-decoration: underline;
            font-size: 1rem;
        }

        strong{
        color: var(--background);
        text-align: center;
        font-size: 1.2rem;
        font-weight: 500;

        &.name{
            color: var(--light);
            font-size: 1.6rem;
            font-weight: bold;
        }
    }
    }
    button.back{
        padding: 0.1rem 4rem;
        border-radius: 1rem;
        background-color: var(--green);
        font-size: 1.4rem;
        color: var(--light);
        transition: filter 0.2s;
        border-width: 0;

         &:hover{
            filter: brightness(0.9);
        }
        
    }

`

export const SecondaryContainer = styled.div`
display: flex;
justify-content: center;
div.card{
    min-width: 300px;
    display: flex;
    margin: 1rem;
    flex-direction: column;
    align-items: center;
    margin-top: 3rem;
    width: 35rem;
    border-radius: 0.875rem;
    background-color: var(--primary);
    padding: 1rem;
    strong{
        color: var(--light);
        text-align: center;
        font-size: 1.5rem;
        &.name{
            font-size: 2rem;
        };
        &.active{
            color:var(--green);
            font-size:1rem;

        };
        &.inactive{
            font-size:1rem;
            color:var(--red);
        }
    }
    label{
        color:var(--gray);
    }
    button{
        
        margin-top: 1rem;
        padding: 0.5rem 1rem;
        font-size: 1.2rem;
        border-radius: 0.5rem;
        border-width: 0;
        color: var(--light);
        background-color: var(--green);
        
    &.exclude{
        font-size: 1.1rem;
        width: 10rem;
        padding: 0.2rem;
        background-color: var(--red);
    }
    
    }
}
`