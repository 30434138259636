import { Button, Container, Logo, Title } from "./styles";

import logoImg from '../../../assets/logo.svg'
import { useNavigate } from "react-router-dom";

export function Header() {

    const navigation = useNavigate()

    return (
        <Container>
            <Logo className="logo" src={logoImg} alt="logo" />
            <Title>
                Todos os produtos TRUELIFE em um só lugar
            </Title>
            <Button onClick={() => navigation('/profile')} className="profile">
                <img src={require('../../../assets/profile.png')} alt="perfil" />
                <Title>
                    Perfil
                </Title>
            </Button>
        </Container>
    )
}