import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Header } from "../../components/global/Header";
import { useCart } from "../../context/cart";
import { getRequisition } from "../../utils/api";

import Carousel from "../../components/global/Carousel";
import { DetailsPlanCard } from "../../components/pages/Details/PlanCard";
import {
  Container,
  DemandContainer,
  Form,
  Icon,
  PlanContainer,
  ProductContainer,
} from "./styles";

export function Details() {
  const { id } = useParams();
  const navigation = useNavigate();
  const [value, setValue] = useState(0);
  const [month] = useState("1");
  const [plan, setPlan] = useState<any>();
  const [credit, setCredit] = useState("1");
  const { setId, setDuration, setCredits } = useCart();

  useEffect(() => {
    getProduct();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function getProduct() {
    const product = await getRequisition(`/details/${id}`);
    setPlan(product.body);
  }

  function handleCheckin(value: any) {
    if (plan.type === "credit") {
      setId(id);
      setDuration(month);
      setCredits(credit);
      localStorage.removeItem("truepaycart");
      return navigation(`/checkin`);
    }
    localStorage.removeItem("truepaycart");
    setId(id);
    setDuration(value.duration);
    navigation(`/checkin`);
  }

  useEffect(() => {
    if (plan) {
      const creditNumber = parseFloat(credit);

      let value = 0;
      for (const key in plan.credit) {
        if (
          creditNumber >= plan.credit[key].min &&
          creditNumber <= plan.credit[key].max
        ) {
          value = creditNumber * plan.credit[key].value;
        }
      }
      value *= parseFloat(month);
      setValue(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [credit, month, plan]);

  return (
    <>
      <Header />
      <Container>
        {!plan ? (
          <></>
        ) : (
          <>
            {plan.type === "credit" ? (
              <DemandContainer>
                <Icon src={plan.icon} alt="" />
                <strong>Personalize seu Plano</strong>
                <Form>
                  <label htmlFor="credit">Quantidade de créditos/mês</label>
                  <input
                    maxLength={18}
                    type="number"
                    value={credit}
                    onChange={(e) => setCredit(e.target.value)}
                  />
                </Form>

                <strong className="description">
                  {credit} créditos/mês por {month}{" "}
                  {month === "1" ? "mês" : "meses"} R$ {value.toFixed(2)}
                </strong>
                <strong className="price">R$ {value.toFixed(2)}</strong>
                <button onClick={() => handleCheckin(value)}>Confirmar</button>
                <button onClick={() => navigation("/")}>Voltar</button>
              </DemandContainer>
            ) : (
              <PlanContainer>
                <Icon src={plan.icon} alt="" />
                <h1>{plan.name}</h1>
                <ProductContainer>
                  <Carousel>
                    {plan.plans.map((value: any, key: any) => (
                      <div key={key.toString()}>
                        <DetailsPlanCard
                          chave={key.toString()}
                          item={value}
                          onClick={() => handleCheckin(value)}
                        />
                      </div>
                    ))}
                  </Carousel>
                </ProductContainer>
                <button className="back" onClick={() => navigation("/")}>
                  Voltar
                </button>
              </PlanContainer>
            )}
          </>
        )}
      </Container>
    </>
  );
}

//
