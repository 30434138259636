import React, { MouseEvent, useCallback, useEffect, useRef, useState } from "react";
import leftArrow from '../../../assets/arrow_left.svg'
import rightArrow from '../../../assets/arrow_right.svg'
import { Container, ContainerRelativo } from "./styles";

interface CarouselParams {
    children: any
    max_width?: number
}



interface StateCarouselParams {
    width_carousel?: number
    width_children?: number
    qnt_children?: number
    max_width_carousel?: number
}


const Carousel: React.FC<CarouselParams> = ({ children, max_width }: CarouselParams): JSX.Element => {

    const Carousel = useRef<HTMLDivElement>(null)

    const [stateCarousel, setStateCarousel] = useState<StateCarouselParams>()

    const handleCarousel = useCallback(() => {
        if (Carousel.current) {
            const carousel = Carousel.current
            setStateCarousel(
                {
                    ...stateCarousel,
                    width_carousel: carousel.clientWidth,
                    qnt_children: carousel.children.length,
                    width_children: carousel.children.item(0)?.clientWidth,
                    max_width_carousel: ((carousel.children.length - 1) * carousel.children.item(0)?.clientWidth!)
                }
            )
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps

    }, [setStateCarousel, stateCarousel])

    const handleCarouselAction = (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault()

        switch (e.currentTarget.id) {
            case "next":
                return Carousel.current!.scrollLeft += stateCarousel?.width_children!

            case "prev":
                return Carousel.current!.scrollLeft -= stateCarousel?.width_children!

            default:
                return null
        }
    }

    useEffect(() => handleCarousel(), [handleCarousel])

    return (
        <ContainerRelativo>
             <button onClick={handleCarouselAction} id="prev" className="prev"><img src={leftArrow} alt="Left arrow" /></button>
            <Container ref={Carousel}>
                {children}
            </Container> 
                <button onClick={handleCarouselAction} id="next" className="next"><img src={rightArrow} alt="Right arrow" /></button>
        </ContainerRelativo>
    )
}

export default Carousel;

/*

*/