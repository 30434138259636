import axios from "axios";

const url = "https://truepayapi.truelifeai.com.br";
// const url = 'http://localhost:3333'
// const url = 'http://192.168.10.41:3333'
export const api = axios.create({
  baseURL: url,
});

export const getRequisition = async (url: string) => {
  const connect = await api
    .get(url)
    .then(({ data }) => {
      return {
        status: 200,
        body: data,
      };
    })
    .catch((err) => {
      const message = err.response.data;
      const status = err.response.status;
      return { status: status, body: message };
    });

  return connect;
};

export const authGetRequisition = async (url: string) => {
  const token = localStorage.getItem("truepaytoken");
  if (!token) {
    return { status: 401, body: "" };
  }

  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  const connect = await api
    .get(url, config)
    .then(({ data }) => {
      return {
        status: 200,
        body: data,
      };
    })
    .catch((err) => {
      const message = err.response.data;
      const status = err.response.status;
      return { status: status, body: message };
    });

  return connect;
};

export const postRequisition = async (url: string, data: any) => {
  const connect = await api
    .post(url, data)
    .then(({ data }) => {
      return {
        status: 200,
        body: data,
      };
    })
    .catch((err) => {
      const message = err.response.data;
      const status = err.response.status;
      return { status: status, body: message };
    });

  return connect;
};

export const authPostRequisition = async (
  url: string,
  data: any
): Promise<any> => {
  const token = localStorage.getItem("truepaytoken");
  if (!token) {
    return;
  }

  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  const connect = await api
    .post(url, data, config)
    .then(({ data }) => {
      return {
        status: 200,
        body: data,
      };
    })
    .catch((err) => {
      const message = err.response.data;
      const status = err.response.status;
      return { status: status, body: message };
    });

  return connect;
};

export const authDeleteRequisition = async (url: string): Promise<any> => {
  const token = localStorage.getItem("truepaytoken");
  if (!token) {
    return;
  }

  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  const connect = await api
    .delete(url, config)
    .then(({ data }) => {
      return {
        status: 200,
        body: data,
      };
    })
    .catch((err) => {
      const message = err.response.data;
      const status = err.response.status;
      return { status: status, body: message };
    });

  return connect;
};

export const loginVerifyAPI = async () => {
  const token = localStorage.getItem("truepaytoken");
  if (!token) {
    return;
  }

  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  const connect = await api
    .get("/token", config)
    .then(({ data }) => {
      return {
        status: 200,
        body: data,
      };
    })
    .catch((err) => {
      const message = err.response.data;
      const status = err.response.status;
      return { status: status, body: message };
    });

  return connect.status;
};
