import styled from "styled-components";


export const Container = styled.div`
flex: 1;
display: flex;
flex-direction: ${window.innerWidth < 1120 ? 'column-reverse' : 'row'};

div{
    &.cart{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        h1{
            color: var(--light);
        }
        img{
            width: 10rem;
            height: 6.7rem;
        }
        div{
        &.card{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;
            margin-top: 3rem;
            width: 90%;
            max-width: 500px;
            padding: 1rem;
            border-radius: 0.5rem;
            background-color: var(--primary);
        }
        &.info{
            margin-top: 0.5rem;
            width: 100%;
            display: flex;
            padding: 0.5rem;
            flex-direction: row;
            justify-content: space-between;
            
        }
        &.subInfo{
            display: flex;
            flex-direction: column;

            strong{
                color: var(--light);

                &.title{
                    font-size: 1.3rem;
                }
                &.subtitle{
                    font-size: 0.8rem;
                    color: gray;
                }
                &.money{
                    font-size: 1.4rem;
                    color:var(--green)
                }
            }
            }
        
        }

        button{
            border-radius: 1rem;
            padding: 0.4rem 3.1rem;
            background-color: var(--green);
            margin: 1rem;
            font-size: 1.4rem;
            transition: filter 0.2s;
            color: var(--light);
            font-weight: 600;
            border-width: 0;
                &:hover{
                    filter: brightness(0.9);
            }
        }
    }

    &.payment{
        display: flex;
        justify-content: center;
        width: 100%;
        justify-content: space-evenly;
        div.column{
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-bottom: 20px;
        }
        div.button{
            
        }
        button.selector{
            display: flex;
            margin-left: 1rem;
            padding: 0.5rem;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin-top: 3rem;
            min-width: 12rem;
            height: 12rem;
            background-color: var(--secondary);
            border-width: 0;
            border-radius:1rem;
            align-items: center;
            transition: filter 0.2s;

                    &:hover{
                        filter: brightness(0.9);
                    }
            h1{
                font-size: 1rem;
                color: #FFF;
            }
            div.indicator{
                width: 1rem;
                height: 1rem;
                border-radius: 1rem;
                background-color: gray;
            }
        }
    }
}

`