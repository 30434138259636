export const userTerms = `
CONTRATO DE ADESÃO E LICENÇA DE USO DE SOFTWARE E PRESTAÇÃO DE SERVIÇOS

CONTRATADA:  TRUELIFE A. I. SOLUÇÕES EM INFORMÁTICA LTDA., com o seu contrato social registrado na Junta Comercial do Estado de Mato Grosso sob o NIRE: 51 201650471, em data de 28/06/2019, inscrita no CNPJ. sob o nº 34.061.090/0001-42, com sede na Rua Edgar Garcia de Siqueira nº 211, Salas 3 e 4, Centro, na cidade de Sorriso, Estado de Mato Grosso, CEP. 78.890-000, representada neste ato pelo Sr. Sandro Lee da Silva Bento.

CONTRATANTE: Cliente/usuário das soluções apresentadas pela TRUELIFE A. I. SOLUÇÕES EM INFORMÁTICA LTDA. 

CONTRATANTE e CONTRATADA, quando referidos em conjunto, serão denominados Partes e individualmente, Parte.

CONSIDERANDO QUE:

a) A CONTRATADA é legítima titular e proprietária das Soluções de Software como serviços, baseados em inteligência artificial com algoritmo exclusivo e estruturado, Rede Neural própria e acesso a Big Data, devidamente publicadas na sua Plataforma de E-commerce, hospedada na Internet, pelo endereço www.truelifeai.com;

b) A CONTRATANTE leu este instrumento contratual, estando ciente de todas as cláusulas que estipulam conjuntamente os deveres e obrigações e compreendeu que o termo Licença de uso de Solução de Software Como Serviço adquirida, identifica uma solução específica de software como serviço de propriedade da CONTRATADA que escolheu livremente na Plataforma de E-commerce.

As Partes celebram o presente Contrato de Licença de Uso de Software e Prestação de Serviços (“Contrato”), que será regido pelas cláusulas e condições seguintes, nos termos da legislação brasileira aplicável.


CLÁUSULA 1 – DO OBJETO

1.1.	Constitui objeto do presente contrato o licenciamento de uso, sem exclusividade, por tempo determinado da Solução Tecnológica escolhida pela CONTRATANTE na plataforma de e-commerce da CONTRATADA, desenvolvida especificamente para o cumprimento da tarefa atribuída e informada.

1.2. Constitui objeto do presente Contrato, a prestação, pela CONTRATADA à CONTRATANTE, por tempo determinado e pacote de planos adquirido, dos serviços contidos na cláusula 1.1; 

1.3. Aos usuários nomeados licenciados nos termos deste Contrato será permitido usar a Solução de Software como serviço adquirida em conformidade com seu respectivo tipo de usuário.


CLÁUSULA 2 – DA LICENÇA DE USO DO SOFTWARE

2.1. A licença de uso concedida por meio deste Contrato dá a CONTRATANTE e aos seus Usuários Licenciados o direito não exclusivo e intransferível de usar a Solução de Software como serviço unicamente para processamento de seus dados, conforme condições aqui descritas e pelo prazo determinado de acordo com o Plano escolhido na Plataforma de E-commerce da CONTRATADA, sendo vedado à CONTRATANTE:

2.1.1. Ceder, sublicenciar, vender, arrendar, dar em locação ou em garantia, doar, alienar de qualquer forma, transferir, total ou parcialmente, sob quaisquer modalidades, gratuita ou onerosamente, provisória ou permanentemente, a Licença de uso de Solução de Software Como Serviço adquirida, sem a prévia e expressa autorização da CONTRATADA, sujeitando-se a CONTRATANTE em caso de violação desta previsão, às sanções estabelecidas em lei, sem prejuízo das penalidades previstas no Contrato; e

2.1.2. Modificar as características a Licença de uso de Solução de Software Como Serviço adquirida, de qualquer forma, sem a expressa anuência da CONTRATADA, sendo certo que, independentemente de autorização, quaisquer produtos derivados da Licença de uso de Solução de Software Como Serviço adquirida serão sempre de propriedade única e exclusiva da CONTRATADA, sem que assista à CONTRATANTE qualquer direito sobre estes, sendo cobrado inclusive, taxas adicionais de licença nesse caso.

2.2. A CONTRATANTE e seus respectivos Usuários Licenciados terão acesso a Licença de uso de Solução de Software Como Serviço adquirida inicialmente, sempre por meio do ARQUIVO EXECUTÁVEL disponibilizado pela CONTRATADA. Observados os limites e características do ambiente operacional da CONTRATANTE, a CONTRATADA garante o acesso ao a Licença de uso de Solução de Software Como Serviço adquirida exclusivamente em sistema operacional WINDOWS a partir da versão 7.


CLÁUSULA 3 – DA PRESTAÇÃO DE SERVIÇO DE SUPORTE TÉCNICO

3.1. A CONTRATADA prestará serviços de suporte técnico, relativo a Licença de uso de Solução de Software Como Serviço adquirida, desde que solicitado pela CONTRATANTE via sistema de chamados, acessível por meio do suporte: suporteiac@truelifeai.com,

3.1.1. A prestação de serviço de suporte será realizada em dias úteis e no horário de 09:00 horas às 17:00 horas (HORÁRIO DE BRASÍLIA) e ainda poderá ser realizada remotamente, via internet, desde que não haja impedimento técnico por parte da CONTRATANTE. 

3.2. O serviço de suporte abrange tão somente o suporte técnico a Licença de uso de Solução de Software Como Serviço adquirida, não incluindo a prestação de serviços de consultoria, customização/parametrização, correção de erros operacionais, desenvolvimento de novas funcionalidades, interfaceamento com outros sistemas e etc.

3.2.1. O serviço de suporte se dará em relação a: 
(i) questões de problemas relacionadas ao uso ou operação Licença de uso de Solução de Software Como Serviço adquirida; 
(ii) apoio para identificar e verificar as causas de possíveis erros ou mau funcionamento Licença de uso de Solução de Software Como Serviço adquirida; 
(iii) orientação sobre soluções alternativas para erros ou mau funcionamento; 
(iv) correção de “Bugs” e defeitos Licença de uso de Solução de Software Como Serviço adquirida.

3.2.2. Em caso de necessidade de suporte técnico além do padrão descrito no item acima, este deverá ser solicitado pela CONTRATANTE e deverão ser aprovados os custos adicionais para este serviço.

3.3. Para a prestação do serviço de suporte, a CONTRATADA cumprirá o seguinte PRIORIDADE DE ATENDIMENTO TRUELIFE (PAT):
a) Emergencial - Situação na qual a Licença de uso de Solução de Software Como Serviço adquirida não processa.
a.i) O suporte técnico deverá ter início em até 4 horas após a comunicação por	parte da CONTRATANTE.
b) Alta – Situação na qual uma ou mais funções do sistema não   processam.
b.i) O suporte técnico deverá ter início em até 6 horas após a comunicação por parte da CONTRATANTE.
c) Média – Situação na qual a operação pode ser realizada, mas devido a erro ela não ocorre da forma convencional.
c.i) O suporte técnico deverá ter início em até 8 horas após a comunicação por parte da CONTRATANTE.
d) Baixa – Situação na qual Licença de uso de Solução de Software Como Serviço adquirida não permite acesso e o uso de cadastros e relatórios.
d.i) O suporte técnico deverá ter início em até 16 horas após a comunicação por parte da CONTRATANTE.

3.3.1. Os prazos de PAT acima descritos terão a contagem a partir do aceite no sistema e serão interrompidos nos seguintes status: 
(i) Aguardando Usuário; 
(ii) Homologação; e 
(iii) Aguardando implantação em produção.

3.3.2. Eventuais atualizações serão liberadas automaticamente pela CONTRATADA e ocorrerão na próxima chamada da Licença de uso de Solução de Software Como Serviço adquirida.

3.3.3. A CONTRATANTE declara estar ciente e concordar que quaisquer modificações na Licença de uso de Solução de Software Como Serviço adquirida e todos os direitos associados a estas serão propriedade da CONTRATADA e esta não concederá, expressa ou implicitamente, quaisquer direitos, títulos, interesses ou licenças sobre as modificações e melhorias introduzidas à CONTRATANTE e/ou a terceiros.


3.4. A CONTRATADA disponibiliza MANUAL DE INSTRUÇÕES AO USUÁRIO, o qual a CONTRATANTE declara conhecer e aceitar, sem qualquer ressalva, comprometendo-se, inclusive, a assegurar que todos os seus Usuários Licenciados tenham total conhecimento do teor do MANUAL e assumam o compromisso de segui-lo em todas as suas condições.

CLÁUSULA 4 – DOS PREÇOS E FORMA DE PAGAMENTO

4.1. Pelo licenciamento do direito de uso, sem exclusividade, da solução tecnológica Licença de uso de Solução de Software Como Serviço adquirida, objeto do presente Contrato, incluindo Implantação, parametrização e treinamento da equipe, a CONTRATANTE pagará à CONTRATADA os valores definidos na Plataforma de E-Commerce, conforme o Plano escolhido. 


4.2. Os valores indicados nos Planos publicados na Plataforma de E-commerce poderão ser reajustados pelo Índice de IGP-M (Índice Geral de Preços – Mercado), a qualquer tempo, sem prévio aviso.


4.3. Fica devidamente ressalvado que, na hipótese de criação ou extinção de tributos, tarifas, taxas, encargos, contribuições fiscais, parafiscais ou previdenciárias, bem como se forem modificadas as alíquotas dos tributos atuais ou se for dada nova interpretação pelo Fisco e/ou Tribunais à arrecadação desses, os valores relativos ao objeto deste Contrato serão revisados, de modo a refletirem tais modificações e preservar o equilíbrio econômico-financeiro deste instrumento.

CLÁUSULA 5 – DO PRAZO DE VIGÊNCIA E RESCISÃO

5.1. O presente Termo vigerá por tempo indeterminado, tendo como base a aquisição de quaisquer Licenças de Uso de Soluções de Software Como Serviço adquiridas, na Plataforma de E-commerce da CONTRATADA a contar da data de aceite na ocasião de cadastramento da CONTRATANTE. 

5.2. Qualquer das Partes poderá declarar o presente Contrato rescindido, a qualquer tempo e imotivadamente, mediante comunicação por escrito ou por e-mail à outra Parte com antecedência mínima de 30 (trinta) dias, não ficando sujeita a qualquer penalidade, responsabilidade por perdas e danos ou qualquer obrigação adicional. Nesta hipótese, a CONTRATADA interromperá a execução do Contrato imediatamente, ficando a CONTRATANTE impedida de acessar a Licença de uso de Solução de Software Como Serviço adquirida.

5.2.1. De acordo com a LEI GERAL DE PROTEÇÃO DE DADOS, Licença de uso de Solução de Software Como Serviço adquirida não salva documentos, faz backup ou quaisquer outros tipos de arquivamento nos seus servidores e Data Center. O processamento da prestação de serviços indicados no objeto deste instrumento, são feitos e salvos localmente no sistema de dados da CONTRATANTE e pela CONTRATANTE.

5.2.1.2. A CONTRATANTE não se responsabilizará por backups (cópias de segurança) dos documentos processados pela Licença de uso de Solução de Software Como Serviço adquirida, pelo período de vigência deste instrumento contratual ou posteriormente ao seu encerramento.


5.3. O presente Contrato será resolvido, de pleno direito, na hipótese de insolvência civil, decretação de falência ou pedido de recuperação judicial ou extrajudicial de qualquer das Partes, bem como na ocorrência de evento de força maior ou caso fortuito, conforme definidos em lei, que cujos efeitos perdurem por mais de 30 (trinta) dias contados da data de comunicação, por escrito, da ocorrência do evento.

5.4. O Contrato poderá ser rescindido, ainda, motivadamente, por qualquer uma das Partes, mediante envio de notificação por escrito a outra Parte, nas seguintes hipóteses:

a) Infração de qualquer cláusula ou condição que não seja sanada no prazo de até 30 (trinta) dias contados do envio de notificação pela Parte que for prejudicada com o inadimplemento;

b) Inadimplência no pagamento dos valores devidos pela CONTRATANTE, superior a 60 (sessenta) dias consecutivos, contados da data de vencimento do documento de cobrança.

5.5.1. Caso qualquer das Partes opte por rescindir o Contrato motivadamente, nos moldes do item 5.4 supra, o Contrato será considerado rescindido, de pleno direito, na data do recebimento da comunicação com tal efeito. Nesta hipótese, a Parte Infratora incorrerá em multa rescisória correspondente a 20% (vinte por cento) do valor correspondente a 12 (doze) meses do Contrato, sem prejuízo das indenizações pelas perdas e danos gerados e das multas diárias que tenham se acumulado até a data da rescisão, nos termos do Artigo 416, caput, e parágrafo único do Código Civil Brasileiro.


CLÁUSULA 6 - DAS OBRIGAÇÕES DA CONTRATADA

6.1. Disponibilizar à CONTRATANTE, a Licença de uso de Solução de Software Como Serviço adquirida e suas as respectivas atualizações, se houver, sem custos adicionais, pelo período vigente deste instrumento contratual;

6.2. Prestar o Suporte Técnico conforme as exigências técnicas da Licença de uso de Solução de Software Como Serviço adquirida e licenciada.

6.3. Obter e manter em vigor, às suas expensas, quaisquer licenças ou autorizações que sejam necessárias para a execução do Contrato;

6.4. Executar os serviços com integral observância das disposições deste Contrato, em estrita conformidade com o disposto na legislação aplicável, respondendo diretamente por sua qualidade e adequação observando os padrões de segurança. Ainda, deverá prestar seu serviço com a melhor técnica, preceitos éticos e através de profissionais qualificados e dentro dos melhores padrões de qualidade para serviços semelhantes.

6.5. Arcar com todas as despesas de mão de obra, materiais, equipamentos, bem como todos e quaisquer custos necessários ao cumprimento integral e oportuno de suas obrigações.

6.6. Preservar o padrão de qualidade técnica e assegurar a continuidade na execução dos Serviços.

6.7. Prestar à CONTRATANTE todas as informações que lhe forem solicitadas durante a execução dos Serviços, em prazo razoável e que guarde pertinência com a urgência dos Serviços.

6.8. A CONTRATADA é a única e exclusiva responsável pelos contratos de trabalho de seus empregados e subcontratados, inclusive nos eventuais inadimplementos trabalhistas em que possa incorrer, não podendo ser arguida solidariedade da CONTRATANTE, nem mesmo responsabilidade subsidiária, não existindo qualquer vínculo empregatício entre a CONTRATANTE e os empregados e subcontratados da CONTRATADA, seja a que título for.



CLÁUSULA 7 - DAS OBRIGAÇÕES DA CONTRATANTE

7.1. Escolher e pagar o Plano de Aquisição da Licença de uso de Solução de Software Como Serviço à CONTRATADA, observadas as condições previstas neste Contrato. 

7.2. Assegurar o acesso aos técnicos da CONTRATADA às suas instalações, sempre que necessário, para o perfeito andamento e execução dos Serviços e garantir a disponibilidade integral de um preposto durante a realização de visitas e/ou Acesso Remoto.

7.3. Manter backups (cópias de segurança) dos documentos processados pela Licença de uso de Solução de Software Como Serviço adquirida e validados pelo usuário, caso se faça necessária à recuperação de informações, ficando isenta a CONTRATADA de responsabilidade em caso de danos ou perda de dados.

7.4. Garantir que somente os técnicos da CONTRATADA ou do parceiro por ela indicado realizem os serviços de suporte previstos neste Contrato.

7.5. A CONTRATANTE deverá promover todos os meios ao seu alcance para facilitar a execução do Serviço, disponibilizando todas as informações e documentos necessários para a realização do Serviço em linguagem clara e compreensível.

7.6. A CONTRATANTE deverá acompanhar os Serviços da CONTRATADA, responsabilizando-se conjuntamente com esta por falhas no processo produtivo estabelecido durante a execução do Serviço, salvo aquelas de culpa exclusiva da CONTRATADA.

7.7. Preservar as configurações mínimas de Hardware e de conexão de internet exigidas.

7.4.1. Responsabilizar-se, integralmente, pela guarda e utilização das senhas/ID (login), arcando com todas as consequências do uso da senha por pessoas não autorizadas. A CONTRATADA em nenhuma hipótese poderá ser responsabilizada pelos prejuízos causados à CONTRATANTE ou a terceiros decorrentes do uso indevido das senhas.



CLÁUSULA 8 – DO SIGILO, CONFIDENCIALIDADE DAS INFORMAÇÕES E PRIVACIDADE DOS DADOS

8.1. As Partes obrigam-se por si, seus prepostos e empregados a manter o mais completo e absoluto sigilo sobre os dados uma da outra para fins deste contrato, sejam eles de interesse das Partes ou de terceiros, não podendo, sob qualquer pretexto, divulgar, reproduzir, utilizar ou deles dar conhecimento a terceiros, sob as penas da Lei.

8.2. Deve ser tratada como confidencial toda e qualquer informação referente ao presente Contrato, Informações contidas na Licença de uso de Solução de Software Como Serviço adquirida, bem como qualquer informação oriunda de atividades ou levadas ao conhecimento das partes durante a execução deste Contrato, sendo vedada a extração de cópias e a guarda de documentos das partes. A confidencialidade das informações persiste mesmo depois de extinto o presente Contrato, devendo ambas as partes respeitar sob pena de responder por perdas e danos.

8.2.1. A CONTRATANTE fica ciente de que todo o material utilizado pela CONTRATADA para a execução deste Contrato, incluindo os sistemas, os códigos fontes, programas, programas de acesso, documentos, configurações, ou outros materiais contidos nos Sistemas, nas atualizações e nas manutenções são de propriedade exclusiva da CONTRATADA, e são consideradas Informações Confidenciais. Portanto, a CONTRATANTE jamais poderá copiá-los, reproduzi-los, distribuí-los, revelá-los, disseminá-los ou comunicá-los de qualquer maneira a terceiros. Da mesma forma, a CONTRATANTE não poderá utilizar tal material para quaisquer outras finalidades estranhas à prestação dos serviços objeto deste Contrato.

8.2.2. O dever de confidencialidade previsto nesta cláusula permanecerá íntegro durante o prazo de vigência deste Contrato, incluindo suas prorrogações, e mesmo após o término deste Contrato, por um prazo adicional de 5 (cinco) anos, ficando a parte que descumprir tal obrigação sujeita à indenização da Parte lesada pelas perdas e danos efetivamente suportados.

8.3. Cada uma das Partes deve possuir, e não deve ser obrigada a compartilhar, qualquer um dos Dados que mantém ou recolhe no que diz respeito a este Instrumento. Dados de uma das Partes será considerado como Informação Confidencial de tal Parte.

8.3.1. "Dados Pessoais" significam quaisquer informações relativas à pessoa física identificada ou identificável; a pessoa identificável é aquela que pode ser identificada, direta ou indiretamente, em particular, por referência a um identificador como o nome, número de identificação, dados da localidade, identificador online ou um ou mais fatores específicos à identidade física, fisiológica, genética, mental, econômica, cultural ou social daquela pessoa física.

8.3.2. As Partes concordam que o presente Contrato poderá envolver a transferência entre as Partes de dados de terceiros e/ou informações pessoais, que possam ser obtidos ou utilizados pelas Partes em decorrência da execução do objeto deste Contrato, os quais deverão ser recolhidos, utilizados, tratados, armazenados e mantidos de acordo com as disposições da LGPD – LEI GERAL DE PROTEÇÃO DE DADOS, nº 13.709/2018.

8.3.3. As Partes concordam que (i) qualquer Dado Pessoal obtido da outra Parte deve ser processado apenas para propósitos comerciais legítimos e retido apenas pelo tempo necessário, conforme as hipóteses taxativas previstas no art. 7º da Lei 13.709/2018, (ii) o acesso a Dados Pessoais será limitado aos(às) empregados(as) das Partes que tiverem necessidades comerciais legítimas para acessá-las e (iii) as Partes não disponibilizarão Dados Pessoais a qualquer terceiro(a), incluindo fornecedores, exceto se expressamente autorizado por escrito pela outra Parte. As Partes concordam em responsabilizar empregados (as) por violações a este Instrumento, incluindo a imposição de sanções e, quando apropriado, rescindir contratações e vínculos de trabalho. Dados Pessoais às vezes permanecerão como propriedade das Partes. As Partes não deverão alugar ou vender Dados Pessoais para quaisquer propósitos. As Partes não deverão utilizar Dados Pessoais de quaisquer maneiras que prejudiquem a outra Parte ou que beneficiem seus concorrentes.




CLÁUSULA 9 – DAS RESTRIÇÕES AO USO DA SOLUÇÃO DE SOFTWARE COMO SERVIÇO ADQUIRIDA

9.1. O presente Contrato é regido pelas disposições da Lei 9.279/96, Lei 9.609/98 e Lei 9.610/98, ficando os infratores sujeitos às penas dos crimes previstos, sem prejuízo da responsabilidade civil pelos danos eventualmente causados pelo uso e distribuição de cópias não autorizadas ou por qualquer outra violação aos direitos decorrentes da propriedade da Licença de uso de Solução de Software Como Serviço adquirida.

9.2. A Licença de uso de Solução de Software Como Serviço adquirida é protegida por leis e tratados de direitos autorais e de propriedade intelectual. A titularidade de todos e quaisquer direitos autorais e de propriedade intelectual sobre a Licença de uso de Solução de Software Como Serviço adquirida é da CONTRATADA. É licenciado o direito de uso da Licença de uso de Solução de Software Como Serviço adquirida à CONTRATANTE pelo prazo expresso no Plano de uso adquirido, não havendo, em nenhuma hipótese, qualquer cessão de direitos relativos à propriedade do mesmo.

9.3. É vedado qualquer procedimento que implique engenharia reversa, descompilação, desmontagem, tradução, adaptação e/ou modificação da Licença de uso de Solução de Software Como Serviço adquirida, ou qualquer outra conduta que possibilite o acesso ao seu código fonte, bem como qualquer alteração não autorizada ou de suas funcionalidades. Assim, como, é vedado, qualquer procedimento que implique no aluguel, arrendamento, empréstimo, seja total ou parcial, da Licença de uso de Solução de Software Como Serviço adquirida a terceiros.

9.4. A extinção do presente instrumento, por qualquer meio, resulta na proibição do uso da Licença de uso de Solução de Software Como Serviço adquirida.

9.5. A CONTRATADA não se responsabiliza pelos resultados produzidos pela Licença de uso de Solução de Software Como Serviço adquirida, caso esta seja afetada por algum tipo de programa externo, como aqueles conhecidos popularmente como vírus, ou por falha de operação.



CLÁUSULA 10 – DAS PENALIDADES

10.1. Caso qualquer das Partes deixe de cumprir obrigação prevista neste Contrato, a Parte Prejudicada notificará a Parte Infratora, mediante simples comunicação por escrito, indicando qual obrigação não foi cumprida, devendo a Parte Infratora cumpri-la dentro do prazo de 5 (cinco) dias corridos, ou outro que venha a ser acordado entre as Partes, contados do recebimento da notificação da Parte Prejudicada.

10.1.1. Na hipótese de a obrigação não ser sanada pela Parte Infratora em tal prazo, esta incorrerá em multa equivalente a 2% (dois por cento) do valor referente a fatura do período em que ocorrer o inadimplemento, por dia em que perdurar o inadimplemento. A multa diária terá caráter moratório e não compensatório.

10.2. As partes se obrigam a pagar o valor das penalidades que lhe forem impostas em virtude do disposto neste Contrato, no prazo máximo de 10 (dez) dias, contados do recebimento de notificação por escrito.

10.3. As penalidades estabelecidas neste Contrato não eximem a Parte Infratora da responsabilidade por eventuais danos e/ou prejuízos causados à Parte Prejudicada, em consequência do inadimplemento das condições contratuais ora estabelecidas.

10.4. As multas, indenizações e penalidades previstas neste Contrato estão limitadas ao valor total do Contrato. Outrossim, a responsabilidade das Partes se limita a eventuais danos diretos, ficando expressamente excluída a responsabilidade por danos indiretos, inclusive lucros cessantes. As limitações de responsabilidade ora estabelecidas não serão aplicáveis às hipóteses de:

(i) Violação de direito de propriedade intelectual e violação da Marca da CONTRATADA;
(ii) Fraude por ambas as Partes;
(iv) Violação à legislação trabalhista, previdenciária e tributária por ambas as Partes;
(vi) Violação aos ditames constantes da Lei Anticorrupção por ambas as Partes.





CLÁUSULA 11 – DAS DISPOSIÇÕES FINAIS

11.1 A execução do presente Contrato, não gera nenhuma obrigação de natureza trabalhista para a CONTRATANTE, sendo de inteira responsabilidade da empresa prestadora os tributos incidentes sobre as operações ora contratadas, assim como por todas e quaisquer obrigações trabalhistas decorrentes da prestação de serviços, desonerando, desta forma, qualquer corresponsabilidade da CONTRATANTE.

11.2 A CONTRATADA responde integralmente por todos os salários, encargos trabalhistas e previdenciários, impostos, seguros, indenizações e todos os outros dispêndios ocasionados pelo vínculo empregatício, por ela mantido com seus funcionários e demais contratados seus que tomem parte no cumprimento das obrigações contratuais que cabem à CONTRATADA.

11.3 Fica expressamente acordado entre as Partes que qualquer nulidade de disposição do presente Contrato não afetará as demais, as quais permanecerão em vigor e efeito até o seu termo.

11.4 As Partes abaixo assinados declaram, sob as penas da lei, que detém condição de procuradores e/ou representantes legais, na forma dos respectivos Estatutos ou Contratos Sociais e instrumentos públicos de procuração em vigor, com poderes para assumir as obrigações contraídas neste instrumento.

11.5 Em hipótese alguma, os pactos deste contrato ensejarão interpretação de existir quaisquer vínculos ou obrigação trabalhista, previdenciária ou que a título for entre os representantes, prepostos, contratados, colaboradores e/ou empregados de qualquer das partes. Diante disso, a Parte responsável acudirá à autoria e diligência no intuito de excluir a parte não responsável que eventualmente figurar no polo passivo da demanda, reclamação trabalhista ou qualquer ação judicial, exonerando e isentando as demais de qualquer ônus ou encargos.

11.6 A eventual tolerância, por quaisquer das contratantes, a respeito da inobservância ou inexecução, ainda que parcial, pela outra parte, de quaisquer cláusulas ou condições deste Contrato constituirá mera liberalidade e não será considerada novação, dação, transação, compensação e/ou remissão, tampouco renúncia ao direito de exigir o pleno cumprimento das obrigações pela parte inadimplente.

11.7 Concordam as Partes que a cessão total ou parcial do presente Contrato dependerá de concordância expressa, por escrito, das outras Partes, exceto no caso de cessão ou transferência a afiliada ou controlada, direta ou indireta, das Partes.

11.8 Este Contrato vincula as Partes e seus sucessores a qualquer título.

11.9 A tolerância ao descumprimento, ainda que reiterado, por qualquer Parte, das disposições contidas neste Contrato não deverá ser interpretada pelas outras Partes como renúncia ou novação.

11.10 Este Contrato só poderá ser alterado, em qualquer de suas disposições, mediante a celebração por escrito de Termo Aditivo.

CLÁUSULA 12 - DO FORO

12.1. Fica eleito o foro da cidade de Sorriso-MT para dirimir quaisquer dúvidas ou controvérsias oriundas deste contrato, com renúncia expressa a qualquer outro, por mais especial e privilegiado que seja. As Partes se comprometem a requerer tramitação sob segredo de Justiça no caso de qualquer demanda que exija a revelação de dados de usuários ou clientes da CONTRATANTE.

E, por assim estarem justas e contratadas, declaram o aceite.


`