import { CheckButton, Container, ModalContainer } from "./styles"
import { useState } from "react";
import { userTerms } from "../../../../utils/userTerms";
import Modal from 'react-modal';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};

interface Props{
    check:boolean
    change:any
}

export function UserTermsModal({check, change}:Props) {

    const [modalIsOpen, setIsOpen] = useState(false);
    function openModal() {
        setIsOpen(true);
    }

    function afterOpenModal() {
        // references are now sync'd and can be accessed.

    }

    function closeModal() {
        setIsOpen(false);
    }

    return (
        <Container>
            <CheckButton onClick={change}>
                {
                    check? 'x' : ''
                }
            </CheckButton>
            <h5>
                Aceito os
            </h5>
            <button className="terms" onClick={openModal}>Termos de Uso</button>
            <Modal
                isOpen={modalIsOpen}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
            >
                
                <ModalContainer>
                <textarea defaultValue={userTerms}></textarea>
                <button onClick={closeModal}>Voltar</button>
                </ModalContainer>
            </Modal>
            
        </Container>
    )
}