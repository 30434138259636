import {
    BrowserRouter as Router,
    Route,
    Routes,
   // Navigate
} from 'react-router-dom'
import { Checkin } from './Pages/Checkin'
import { Checkout } from './Pages/Checkout'
import { Details } from './Pages/Details'

import { Home } from './Pages/Home'
import { Login } from './Pages/Login'
import { Profile } from './Pages/Profile'
import { Register } from './Pages/Register'

export function AppRoutes(){
    return(
        <Router>
            <Routes>
                <Route path="/" element={<Home />}/>
                <Route path="/details/:id" element={<Details />}/>
                <Route path="/checkin" element={<Checkin />}/>
                <Route path="/Register" element={<Register />}/>
                <Route path="/login" element={<Login />}/>
                <Route path="/profile" element={<Profile />}/>
                <Route path="/checkout" element={<Checkout />}/>
            </Routes>
        </Router>
    )
}