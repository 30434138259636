import styled from "styled-components";

export const Container = styled.div`
margin-top: 30px;
display: flex;
flex-direction: row;
button.terms{
    margin-left: 10px;
    text-decoration: underline;
    background-color: transparent;
    background-repeat: no-repeat;
    border: none;
    cursor: pointer;
    overflow: hidden;
    outline: none;
}
`

export const ModalContainer = styled.div`
display: flex;
flex-direction: column;

button{
    
    padding: 1rem 2rem;
    font-size: 1rem;
    font-weight: bold;
    color:var(--light);
    margin: 2rem;
    border-width: 0;
    transition: filter 0.2s;
    border-radius: 1rem;
    background-color: var(--green);

    &:hover{
        filter: brightness(0.9);
    }
}

strong{
    width: 36rem;
    color: var(--green);
}

textarea{
    width: 36rem;
    font-weight: bold;
    font-size: 1.2rem;
    color: var(--light);
    height: 60vh;
    margin-top: 1rem;
    padding: 0.5rem;
    resize:none;
    text-align: justify;
    padding: 10px;
    border-radius: 1rem;
    border-color: gray;
    background-color: var(--primary);
}

`

export const CheckButton = styled.button`
width: 1rem;
height: 1rem;
border: none;
display: flex;
align-items: center;
justify-content: center;
margin-right: 5px;
border-radius: 0.3rem;
`