import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    max-width:1120px;
    align-items: center;
    justify-content: center;

    tbody{
        display: flex;
                
    }
    div{

        font-size: 1.3rem;
        color: var(--light);
        overflow: hidden;
        &.secondary{
            margin-top: 10px;
            font-size: 0.875rem;
        }
        &.title{
            padding: 0.4rem;
            width: 100%;
            text-align: center;
            background-color: black;
        }

        &.green{
            margin-top: 5px;
            color: var(--green);
        }
        &.body{
        border-radius: 0.5rem;
        margin: 1rem;
        display: flex;
        flex-direction: column;
        width: 20rem;
        align-items: center;
        background-color: var(--primary);
    }
    button{
                padding: 0.2rem 0.6rem;
                border-radius: 0.5rem;
                background-color: var(--green);
                margin: 1rem;
                font-size: 1rem;
                transition: filter 0.2s;
                border-width: 0;

                    &:hover{
                        filter: brightness(0.9);
                    }
            }
}    
`

