import { useNavigate } from "react-router-dom";
import { Container } from "./styles";

export function Checkout() {

    const navigation = useNavigate()

    return (
        <Container>
            <img src={require('../../assets/logo.png')} alt="" />
            <strong>
            Obrigado por adquirir uma Solução Truelife! Agora você já pode usufruir da melhor tecnologia em Inteligência 
            Artificial com Visão computacional Cognitiva do mercado
            </strong>
            <div>
            <button onClick={()=>navigation('/')} >
                ir para a tela Inicial
            </button>
            <button onClick={()=>navigation('/profile')}>
                Ir para Meu Perfil
            </button>
            </div>
        </Container>
    )
}