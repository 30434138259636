import styled from "styled-components"

export const ContainerRelativo = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  
    button.prev {
      background: #00000000;
      border: none;
      width: 40px;
      height: 40px;
      cursor: pointer;
      img{
        width: 5vh;
        height: 5vh;
      }
    }
    button.next {
      background: #00000000;
      border: none;
      width: 40px;
      height: 40px;
      cursor: pointer;
      img{
        width: 5vh;
        height: 5vh;
      }
    }
  
`

export const Container = styled.div`
  margin: auto;
  max-width: 80vw;
  overflow-x: scroll;
  display: flex;
  scroll-behavior: smooth;
  &::-webkit-scrollbar {
    display: none;
  }
 
`