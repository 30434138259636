import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
:root{
    --background: #2A2E2F;
    --red:#C02948;
    --green: #00B800;

    --primary:#393E40;
    --secondary:#4c4c4c;
    --gray:#BBBCB8;

    --dark: #000;
    --light:#ffffff;
    --header:#111316;

    --blue:#40A5DD;
}

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html{
    @media(max-width:1080px){
        font-size: 93.75%;
    }
    @media(max-width: 720px){
        font-size: 87.5%;
    }
}

body, input, textarea, button{
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
}

h1, h2, h3, h4, h5, h6, strong{
    font-weight: 600;
}

body{
    background: var(--background);
    -webkit-font-smothing:atialiased;
}

button{
    cursor:pointer
}

[disabled]{
    opacity: 0.6;
    cursor: not-allowed;
}

`