import { useReducer, useState } from "react";
import MaskedInput from "react-text-mask";
import Select from 'react-select'
import { Spinner } from "react-activity";
import "react-activity/dist/Spinner.css";
import {
  AMERICANEXPRESS,
  OTHERCARDS,
  EXPIRYDATE,
  CVC,
  CARDARR,
  CARDICON
} from "../../../utils/creditCardConstant";
import { fieldNameValidation, minLength, stripeCardExpirValidation, stripeCardNumberValidation, textWithSpacesOnly } from "../../../utils/creditCardValidation";
import { maskCpfCnpj } from "../../../utils/masks";
import { maskCep, maskPhone } from "../../../utils/textInputFormat";
import { BottomBox, Container, Error, Inputs, Wrapper } from "./styles";
import { authPostRequisition } from "../../../utils/api";
import { useAlert } from 'react-alert'
import { useNavigate } from "react-router-dom";

export function CreditCardForm({ props, details, installment }: any) {

  const reducer = (state: any, action: any) => {
    switch (action.type) {
      case "card":
        return { ...state, card: action.data };
      case "expiry":
        return { ...state, expiry: action.data };
      case "securityCode":
        return { ...state, securityCode: action.data };
      case "cardHolder":
        return { ...state, cardHolder: action.data };
      case "cleanState":
        return { ...action.data };
      default:
        return state;
    }
  };

  function findDebitCardType(cardNumber: any) {
    const regexPattern: any = {
      MASTERCARD: /^5[1-5][0-9]{1,}|^2[2-7][0-9]{1,}$/,
      VISA: /^4[0-9]{2,}$/,
      AMERICAN_EXPRESS: /^3[47][0-9]{5,}$/,
      DISCOVER: /^6(?:011|5[0-9]{2})[0-9]{3,}$/,
      DINERS_CLUB: /^3(?:0[0-5]|[68][0-9])[0-9]{4,}$/,
      JCB: /^(?:2131|1800|35[0-9]{3})[0-9]{3,}$/
    };
    for (const card in regexPattern) {
      if (cardNumber.replace(/[^\d]/g, "").match(regexPattern[card])) return card;
    }
    return "";
  }
  const alert = useAlert()
  const navigation = useNavigate()
  const [loading, setLoading] = useState(false)
  const [name, setName] = useState('')
  const [number, setNumber] = useState('')
  const [postalCode, setPostalCode] = useState('')
  const [phone, setPhone] = useState('')
  const [cpfCnpj, setCpfCnpj] = useState('')
  const [error, setError] = useState<any>({});
  const [answer, setAnswer] = useState('')
  const [cardType, setCardType] = useState('');
  const [selectedInstall, setSelectedInstall] = useState<any>()
  const [state, dispatch] = useReducer(reducer, {
    card: "",
    expiry: "",
    securityCode: "",
    cardHolder: ""
  });


  const handleValidations = (type: any, value: any) => {
    let errorText;
    switch (type) {
      case "card":
        setCardType(findDebitCardType(value));
        errorText = stripeCardNumberValidation(value);
        setError({ ...error, cardError: errorText });
        break;
      case "cardHolder":
        errorText = value === "" ? "Campo obrigatório" : textWithSpacesOnly(value);
        setError({ ...error, cardHolderError: errorText });
        break;
      case "expiry":
        errorText =
          value === "" ? "Campo obrigatório" : stripeCardExpirValidation(value);
        setError({ ...error, expiryError: errorText });
        break;
      case "securityCode":
        errorText = value === "" ? "Campo obrigatório" : minLength(3)(value);
        setError({ ...error, securityCodeError: errorText });
        break;
      case "name":
        errorText = value === "" ? "Campo obrigatório" : textWithSpacesOnly(value);
        setError({ ...error, name: errorText });
        break;
      case "postalCode":
        errorText = value === "" ? "Campo obrigatório" : minLength(9)(value);
        setError({ ...error, postalCodeError: errorText });
        break;
      case "number":
        errorText = value === "" ? "Campo obrigatório" : minLength(1)(value);
        setError({ ...error, numberError: errorText });
        break;
      case "mobilePhone":
        errorText = value === "" ? "Campo obrigatório" : minLength(14)(value);
        setError({ ...error, phoneError: errorText });
        break;
      case "cpfCnpj":
        errorText = value === "" ? "Campo obrigatório" : minLength(14)(value);
        setError({ ...error, cpfError: errorText });
        break;
      default:
        break;
    }
  };

  const handleInputData = (e: any) => {
    dispatch({ type: e.target.name, data: e.target.value });
  };

  const handleBlur = (e: any) => {
    handleValidations(e.target.name, e.target.value);
  };

  /* 

  const checkErrorBeforeSave = () => {
    let errorValue = {};
    let isError = false;
    Object.keys(state).forEach(async (val) => {
      console.log(state[val])
      if (state[val] === "") {
        setAnswer(`${fieldNameValidation(val)} é obrigatório`)
        errorValue = { ...errorValue, [`${fieldNameValidation(val)}`]: "é obrigatório" };
        console.log(errorValue)
        isError = true;
      }
    });
    setError(errorValue);
    return isError;
  };
*/
  const customStyles = {
    control: (base: any, state: { isFocused: any; }) => ({
      ...base,
      background: "#3c3c3c",
      borderRadius: '0.5rem',
      borderWidth: '0.1rem',
      borderColor: '#000',
      textColor: '#fff',
      marginTop: '1rem',
      height: '3rem',
      "&:hover": {
        opacity: 0.9
      }
    }),
    menu: (base: any) => ({
      ...base,
      marginTop: 0,
      width: '15rem',
      borderRadius: '0.5rem',
      color: '#fff',
      backgroundColor: '#2222'
    }),
    dropdownIndicator: (base: any) => ({
      ...base,
      borderRadius: 0,
      color: '#fff',
    }),
    menuList: (base: any) => ({
      ...base,
      padding: 0,

    }),
    option: (base: any) => ({
      ...base,
      width: '15rem',
      backgroundColor:'#3c3c3c'
    }),
  }

  function handleSubmit() {

    const [month, year] = state.expiry.split('/')
    const data = {
      creditCard: {
        holderName: state.cardHolder,
        ccv: state.securityCode,
        number: state.card,
        expiryMonth: month,
        expiryYear: year
      },
      creditCardHolderInfo: {
        name,
        cpfCnpj,
        postalCode,
        addressNumber: number,
        phone,
      },
      installment: selectedInstall ? selectedInstall.value : 1
    }

    const validation = fieldNameValidation(data)
    if (validation !== 'ok') {
      return setAnswer(validation)
    }
    handleBuy(data)
  }

  function handleHome() {
    localStorage.removeItem('id')
    localStorage.removeItem('duration')
    localStorage.removeItem('cart')

    return navigation('/checkout')
  }

  async function handleBuy(item: any) {
    item.details = details
    item.cartId = details._id
    item.billingType = 'credit'
    setLoading(true)
    const generate = await authPostRequisition(`/payment`, item)
    setLoading(false)
    if (generate?.status === 200) {
      alert.show('Transação bem sucedida')
      return handleHome()
    } else {
      setAnswer(generate.body)
    }
  }

  function handleChange(item: any) {
    setSelectedInstall(item)
  }

  return (
    <Container>

      <Wrapper>
        <Inputs>
          <MaskedInput
            mask={
              ["37", "34"].includes(
                state && state.card.split("").splice(0, 2).join("")
              )
                ? AMERICANEXPRESS
                : OTHERCARDS
            }
            guide={false}
            placeholderChar={"\u2000"}
            placeholder="Número do cartão"
            name="card"
            required
            value={state.card}
            onChange={handleInputData}
            onBlur={handleBlur}
          />
          {(!error || !error.cardError) && CARDARR.includes(cardType) && (
            <img
              style={{
                float: "right",
                position: "relative",
                top: "-40px",
                left: '10rem'
              }}
              src={CARDICON[cardType]}
              alt="card"
              width="50px"
              height="33px"
            />
          )}
          {error && error.cardError && error.cardError.length > 1 && (
            <Error>{error.cardError}</Error>
          )}
        </Inputs>
        <Inputs>
          <input
            type="text"
            name="cardHolder"
            required
            placeholder="Nome no Cartão"
            value={state.cardHolder}
            onChange={handleInputData}
            onBlur={handleBlur}
          />
          {error &&
            error.cardHolderError &&
            error.cardHolderError.length > 1 && (
              <Error>{error.cardHolderError}</Error>
            )}
        </Inputs>
        <Inputs >
          <BottomBox>
            <div className="expiry">
              <MaskedInput
                mask={EXPIRYDATE}
                guide={false}
                name="expiry"
                required
                placeholderChar={"\u2000"}
                placeholder="Expiração"
                value={state.expiry}
                onChange={handleInputData}
                onBlur={handleBlur}
              />
              {error &&
                error.expiryError &&
                error.expiryError.length > 1 && (
                  <Error>{error.expiryError}</Error>
                )}
            </div>
            <div className="cvc">
              <MaskedInput
                mask={CVC}
                guide={false}
                name="securityCode"
                required
                placeholderChar={"\u2000"}
                placeholder="CCV"
                value={state.securityCode}
                onChange={handleInputData}
                onBlur={handleBlur}
              />
              {error &&
                error.securityCodeError &&
                error.securityCodeError.length > 1 && (
                  <Error>{error.securityCodeError}</Error>
                )}
            </div>
          </BottomBox>

        </Inputs>
        <h1>
          Informações do Proprietário
        </h1>
        <Inputs>
          <input
            type="text"
            name="name"
            required
            placeholder="Nome do Proprietário"
            value={name}
            onChange={(e) => setName(e.target.value)}
            onBlur={handleBlur}
          />
          {error &&
            error.nameError &&
            error.nameError.length > 1 && (
              <Error>{error.nameError}</Error>
            )}
        </Inputs>
        <Inputs>
          <input
            type="text"
            name="postalCode"
            required
            maxLength={9}
            placeholder="CEP de cobrança"
            value={postalCode}
            onChange={(e) => setPostalCode(maskCep(e.target.value))}
            onBlur={handleBlur}
          />
          {error &&
            error.postalCodeError &&
            error.postalCodeError.length > 1 && (
              <Error>{error.postalCodeError}</Error>
            )}
        </Inputs>
        <Inputs>
          <input
            type="text"
            name="number"
            required
            placeholder="Número da casa"
            value={number}
            onChange={(e) => setNumber(e.target.value)}
            onBlur={handleBlur}
          />
          {error &&
            error.numberError &&
            error.numberError.length > 1 && (
              <Error>{error.numberError}</Error>
            )}
        </Inputs>
        <Inputs>
          <input
            type="text"
            name="mobilePhone"
            required
            placeholder="Telefone do Proprietário"
            value={phone}
            maxLength={14}
            onChange={(e) => setPhone(maskPhone(e.target.value))}
            onBlur={handleBlur}
          />
          {error &&
            error.phoneError &&
            error.phoneError.length > 1 && (
              <Error>{error.phoneError}</Error>
            )}
        </Inputs>
        <Inputs>
          <input
            type="text"
            name="cpfCnpj"
            required
            placeholder="CPF ou CNPJ do proprietário"
            value={cpfCnpj}
            maxLength={18}
            onChange={(e) => setCpfCnpj(maskCpfCnpj(e.target.value))}
            onBlur={handleBlur}
          />
          {error &&
            error.cpfError &&
            error.cpfError.length > 1 && (
              <Error>{error.cpfError}</Error>
            )}
        </Inputs>
        <Select
          isSearchable={false}
          styles={customStyles}
          onChange={handleChange}
          placeholder='Número de parcelas'
          options={installment}
        />
        <strong className="error" >
          {answer}
        </strong>
        <button
          disabled={loading}
          onClick={handleSubmit}
        >
          {loading ? <Spinner /> :
            'Confirmar'
          }
        </button>
      </Wrapper>
    </Container>
  );
}