import styled from "styled-components"

export const Container = styled.header`
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
padding:1rem 0;
background: var(--header);
width: 100%;
`

export const Logo = styled.img`
 width:25vw;
 min-width: 13rem;
`

export const Title = styled.strong`
font-size: min(3vw, 1.5rem);
color:var(--light);
`

export const Button = styled.button` 

&.profile{
        position: absolute;
        right: 1rem;
        display: flex;
        background-color: transparent;
        border-color: transparent;
        flex-direction: column;
        align-items: center;
        justify-content:center ;
        img{
            width: 6vw;
            min-width: 30px;
            max-width: 50px;
            margin-bottom: 0.5vh;
        }
        strong{
            font-size: min(3vw, 1.4rem);
            text-decoration: underline;
        }

        &:hover{
            filter: brightness(0.6) ;
        }
    }
`