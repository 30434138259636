import { Container } from "./styles";

export function DetailsPlanCard({chave, item, onClick }: any) {
    return (
        <Container key={chave}>
                <div className='body' >
                    <div className='title' >
                        {item.name}
                    </div>
                    <div className="secondary">
                        Por apenas
                    </div>
                    <div className='green' >
                        R$ {item.value.toFixed(2)}
                    </div>
                    <div className="secondary">
                        ou até {item.duration}x de
                    </div>
                    <div className='green' >
                        R$ {(item.value / item.duration).toFixed(2)}
                    </div>
                    <button onClick={onClick} >
                        Adquirir Plano
                    </button>
                </div>
        </Container>
    )
}