import styled from 'styled-components'

export const Container = styled.div`

display: flex;
align-items: center;
flex-direction: column;
justify-content: center;

button.back{
    padding: 0.1rem 3rem;
    border-radius: 1rem;
    background-color: var(--green);
    margin: 1rem;
    font-size: 1.3rem;
    transition: filter 0.2s;
    border-width: 0;

        &:hover{
            filter: brightness(0.9);
        }

    }

`

export const Form = styled.div` 
display: flex;
margin-top: 0.5rem;
flex-direction: column;

    input {
        border-radius: 1rem;
        border-width: 0px;
        height: 4rem;
        width: 24rem;
        font-weight: 500;
        font-size: 1.6rem;
        text-align: center;
         padding-left: 0.5rem;
    }
    label {
        font-size: 1.3rem;
        color:var(--light)
    }

        
        
`

export const DemandContainer = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 2rem;
    padding: 1rem 8rem;
    border-radius: 0.5rem;
    background-color: var(--dark-gray);

        strong{
margin-top: 1rem;
font-size: 2rem;
color: var(--light);
        
        &.price{
color: var(--green);
        }
        &.description{
color: var(--light);
font-size: 1rem;
        }
        
    }
    button{
    padding: 0.5rem 4rem;
    border-radius: 1rem;
    background-color: var(--green);
    margin: 1rem;
    font-size: 1.7rem;
    transition: filter 0.2s;
    border-width: 0;
    color: var(--light);
        &:hover{
filter: brightness(0.9);
        }
}
        

`

export const PlanContainer = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 2rem;
    padding: 1rem 8rem;
    border-radius: 0.5rem;
    background-color: var(--dark-gray);
`

export const Icon = styled.img`
width:20vw;
min-width: 13rem;
`

export const ProductContainer = styled.div`
`