import styled from "styled-components";

export const Container = styled.div`

display: flex;
align-items: center;
flex-direction: column;
justify-content: center;

table{
    width:100%;
    display: flex;
    align-items: center;
    justify-content: center;
    tbody{
    
        display: flex;
        scroll-behavior: smooth;
        tr{
          
            button{
                background-color: transparent;
                border-color: transparent;

                transition: filter 0.2s;

                    &:hover{
                        filter: brightness(0.4);
                    }

            }
            img{
                width: 12rem;
                height: 8rem;
            }
        &.plans{
            display: flex;
            padding: 0px;
            overflow: hidden;
            border-radius: 10px;
            align-items: center;
            flex-direction:column;
            justify-content: flex-start;
            background-color: var(--text-body);
            td{
                margin-top: 0.5rem;
                font-size: 1.5rem;
                color: var(--light);
                text-align: center;
                padding: 0.4rem;
                font-size:1.2rem;
                &.footer{
                        font-size: 1rem;
                        padding: 0.1rem;
                        color: var(--background) ;
                    }
            }
       
            button{
                padding: 0.5rem 1rem;
                border-radius: 1rem;
                background-color: var(--green);
                margin: 1rem;
                font-size: 1.1rem;
                transition: filter 0.2s;
                font-weight: 600;

                    &:hover{
                        filter: brightness(0.9);
                    }
            }
       
        }
        
        }    
    }
}



`

export const Title = styled.h2`
 margin: 1rem;
text-align: center;
color: var(--light);
`

export const ProductContainer = styled.div`
`

export const SubTitle = styled.h3`

    margin: 3rem;
    font-size: 1.5rem;
    color: gray;
    text-align: center;

`