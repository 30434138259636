import { useEffect, useState } from "react";
import { Dots } from "react-activity";
import "react-activity/dist/Dots.css";
import { useNavigate } from "react-router-dom";
import Carousel from "../../components/global/Carousel";
import { Header } from "../../components/global/Header";
import { ProductCard } from "../../components/pages/Home/ProductCard";
import { getRequisition } from "../../utils/api";

import { PlanCard } from "../../components/pages/Home/PlanCard";
import { Container, ProductContainer, SubTitle, Title } from "./styles";

export function Home() {
  const navigation = useNavigate();
  const [plans, setPlans] = useState<any[]>([]);
  const [product, setProduct] = useState<any[]>([]);
  const [planLoading, setPlanLoading] = useState(false);
  const [productLoading, setProductLoading] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState<any>("");
  useEffect(() => {
    getProduct();
    handleClear();
  }, []);

  function handleClear() {
    localStorage.removeItem("truepayid");
    localStorage.removeItem("truepayduration");
    localStorage.removeItem("truepaycart");
  }

  async function getProduct() {
    setProductLoading(true);
    const product = await getRequisition("/product");
    if (product.status === 200) {
      setProduct(product.body);
    }
    setProductLoading(false);
  }

  async function getPlans(id: string) {
    setSelectedProduct(id);
    setPlanLoading(true);
    const plans = await getRequisition(`/plans/${id}`);
    if (plans.status === 200) {
      setPlans(plans.body);
    }
    setPlanLoading(false);
  }

  return (
    <>
      <Header />
      <Container>
        <ProductContainer>
          <Title>Produtos Disponíveis</Title>
          {productLoading ? (
            <Dots size={50} />
          ) : (
            <Carousel>
              {product.map((product) => (
                <ProductCard
                  opacity={selectedProduct === product._id ? 0.5 : 1}
                  key={product._id}
                  onClick={() => getPlans(product._id)}
                  img={product.icon}
                />
              ))}
            </Carousel>
          )}
          <Title>Planos Disponíveis</Title>
        </ProductContainer>
        <ProductContainer>
          {planLoading ? (
            <Dots size={50} />
          ) : plans.length === 0 ? (
            <SubTitle>
              Selecione um Produto para conhecer os Planos TrueLife
            </SubTitle>
          ) : (
            <Carousel>
              {plans.map((plans) => (
                <PlanCard
                  onClick={() => navigation(`details/${plans._id}`)}
                  key={plans._id}
                  item={plans}
                />
              ))}
            </Carousel>
          )}
        </ProductContainer>
      </Container>
    </>
  );
}
