import styled from "styled-components";

export const Container = styled.body` 
display: flex;
width: 100%;
height: 100vh;
align-items: center;
justify-content: center;
flex-direction: column;

    img{
        width: 15rem;
        height: 15rem;
    }

    strong{
        font-size: 1.7rem;
        margin: 10px;
        width: 50rem;
        color: white;
        text-align: justify;
    }

    button{
    
    padding: 1rem 2rem;
    font-size: 1.8rem;
    width: 25rem;
    font-weight: bold;
    color:var(--light);
    margin: 2rem;
    border-width: 0;
    transition: filter 0.2s;
    border-radius: 1rem;
    background-color: var(--green);

    &:hover{
        filter: brightness(0.9);
    }
}

`