import { AppRoutes } from './AppRoutes';
import CartProvider from './context/cart';
import { GlobalStyle } from './Global/themes';
import { Provider as AlertProvider } from 'react-alert'
import AlertTemplate from 'react-alert-template-basic'
function App() {
  return (
  <AlertProvider template={AlertTemplate}>
    <CartProvider>
      <GlobalStyle />
      <AppRoutes />
    </CartProvider>
    </AlertProvider>
  );
}

export default App;
