import { createContext, useContext, useEffect, useState } from "react";

const cartContext = createContext();

export default function CartProvider({ children }) {
  const [id, setId] = useState();
  const [credits, setCredits] = useState();
  const [duration, setDuration] = useState();
  const [cart, setCart] = useState();

  const store = {
    id,
    setId,
    credits,
    duration,
    cart,
    setCart,
    setCredits,
    setDuration,
  };

  useEffect(() => {
    if (duration) {
      console.log("entrou");
      setDuration(duration);
      localStorage.setItem("truepayduration", duration);
      if (!cart) {
        localStorage.removeItem("truepaycart");
      }
    }
    if (id) {
      setId(id);
      localStorage.setItem("truepayid", id);
    }
    if (credits) {
      setCredits(credits);
      localStorage.setItem("truepaycredits", credits);
    }
    if (cart) {
      setCart(cart);
      localStorage.setItem("truepaycart", cart);
    }
  }, [id, duration, credits, cart]);

  return <cartContext.Provider value={store}>{children}</cartContext.Provider>;
}

export function useCart() {
  const context = useContext(cartContext);
  const {
    id,
    setId,
    credits,
    duration,
    cart,
    setCart,
    setCredits,
    setDuration,
  } = context;

  return {
    id,
    setId,
    credits,
    duration,
    cart,
    setCart,
    setCredits,
    setDuration,
  };
}
