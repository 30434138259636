import styled from "styled-components";

export const Container = styled.div`
margin-top: 1rem;
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;

img{
    border-radius: 1rem;
}

strong{
    color: var(--red);
    font-size: 1.3rem;

    &.title{
        color: var(--light);
        padding: 5px;
        text-align: center;
    }
}



textarea{
    width: 36rem;
    font-weight: bold;
    font-size: 1.2rem;
    color: var(--green);
    height: 3rem;
    margin-top: 1rem;
    padding: 0.5rem;
    resize:none;
    border-radius: 1rem;
    border-color: gray;
    background-color: var(--primary);
}



button{
    
            padding: 1rem 2rem;
            font-size: 1rem;
            font-weight: bold;
            color:var(--light);
            margin: 2rem;
            border-width: 0;
            transition: filter 0.2s;
            border-radius: 1rem;
            background-color: var(--primary);

            &:hover{
                filter: brightness(0.9);
            }
}
`

export const BillCard = styled.div`
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
padding: 10px;
margin-top: 20px;
margin-left: 10px;
border-radius: 10px;
background-color: var(--blue);
min-width: 200px;
strong{
    &.value{
        color: var(--secondary);
        font-size: 1rem;
    };
    &.date{
        color: var(--light);
        font-size: 0.875rem;
    };
}

a{
    padding: 0.1rem 0.3rem;
    text-decoration: none;
    color: var(--background);
    margin-top: 5px;
    font-weight: bold;
    border-width: 0;
    transition: filter 0.2s;
    border-radius: 0.4rem;
    font-size: 0.7rem;
    background-color: var(--green);
}
`

export const ListContainer = styled.div`
width: 100%;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;

a{
    padding: 1rem 2rem;
    font-size: 1rem;
    font-weight: bold;
    color:var(--light);
    margin: 2rem;
    text-decoration:none;
    border-width: 0;
    transition: filter 0.2s;
    border-radius: 1rem;
    background-color: var(--green);

    &:hover{
        filter: brightness(0.9);
    }
}

button{
    
    padding: 1rem 2rem;
    font-size: 1rem;
    font-weight: bold;
    color:var(--light);
    border-width: 0;
    transition: filter 0.2s;
    border-radius: 1rem;
    background-color: var(--primary);

    &:hover{
        filter: brightness(0.9);
    }
}

strong{
    color: var(--red);
    font-size: 1.3rem;

    &.title{
        color: var(--light);
        margin-top: 10px;
        padding: 5px;
        text-align: center;
    }
}
`