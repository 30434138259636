import { useEffect, useState } from "react";
import { authPostRequisition } from "../../../../utils/api";
import { Container, ListContainer } from "./styles";
import { Spinner } from "react-activity";
import "react-activity/dist/Spinner.css";
import { useNavigate } from "react-router-dom";
import Select from 'react-select'

interface Props {
    id: any,
    quantity: number
    value: number
    selectedType: string
}

export function Bill({ id, quantity, value, selectedType }: Props) {

    const [loading, setLoading] = useState(false)
    const [answer, setAnswer] = useState('')
    const navigation = useNavigate()
    const [selected, setSelected] = useState()
    const [numberOptions, setNumberOptions] = useState<any[]>([])
    const [paymentLink, setPaymentLink] = useState('')
    const customStyles = {
        control: (base: any, state: { isFocused: any; }) => ({
            ...base,
            background: 'var(--primary)',
            borderRadius: '0.5rem',
            borderWidth: '0.1rem',
            color: 'white',
            width: '15rem',
            marginTop: '1rem',
            height: '3rem',

        }),
        menu: (base: any) => ({
            ...base,
            marginTop: 0,
            width: '15rem',
            borderRadius: '0.5rem',
            color: '#fff',
            backgroundColor: '#2222'
        }),
        dropdownIndicator: (base: any) => ({
            ...base,
            borderRadius: 0,
            color: '#fff',
        }),
        menuList: (base: any) => ({
            ...base,
            padding: 0,

        }),
        option: (base: any) => ({
            ...base,
            width: '15rem',
            backgroundColor: '#3c3c3c'
        }),
    }

    function handleChange(item: any) {
        setSelected(item.value)
    }

    useEffect(() => {
        const list = []
        for (let i = 1; i <= quantity; i++) {
            const data = {
                value: i,
                label: `${i}x de R$ ${(value / i).toFixed(2)}`
            }

            list.push(data)
        }
        setNumberOptions(list)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleBill = async () => {

        const body = {
            billingType: 'cash',
            cartId: id,
            installment: selected,

        }
        setLoading(true)
        const generate = await authPostRequisition(`/payment/`, body)
        if (generate.status !== 200) {
            setLoading(false)

            return setAnswer(generate.body)
        }
        setPaymentLink(generate.body.paymentLink ? generate.body.paymentLink : generate.body )
        setLoading(false)

    }


    function handleHome() {
        localStorage.removeItem('id')
        localStorage.removeItem('duration')
        localStorage.removeItem('cart')

        return navigation('/checkout')
    }

    return (
        <>
            {
                selectedType !== 'cash' ? <></> :

                    paymentLink !== '' ?
                        <ListContainer>
                            <strong className="title">
                                Agora basta realizar o pagamento dos boletos nas datas definidas.
                            </strong>
                            <a  rel="noreferrer" href={paymentLink} target="_blank" >Link de Pagamento</a>
                            <button className="home" onClick={() => handleHome()} >
                                Finalizar Compra
                            </button>
                        </ListContainer>
                        :

                        <Container>
                            {


                                loading ?
                                    <Spinner /> :
                                    answer !== '' ?
                                        <strong>{answer}</strong> :


                                        <>
                                            <strong className="title">
                                                Selecione a Quantidade de parcelas que deseja realizar o Pagamento
                                            </strong>
                                            <Select
                                                isSearchable={false}
                                                styles={customStyles}
                                                onChange={handleChange}
                                                placeholder='Número de parcelas'
                                                options={numberOptions}
                                            />
                                            {
                                                !selected ? <></> :
                                                    <button className="home" onClick={() => handleBill()} >
                                                        Gerar Boletos
                                                    </button>
                                            }
                                        </>

                            }
                        </Container >
            }


        </>
    )
}