import styled from "styled-components";

export const Container = styled.button`

 border-radius: 0.5rem;
 border-width: 0;
 margin: 0.5rem;
 background-color: var(--primary);

img{
    width: 10rem;
    height: 10rem;
}
`